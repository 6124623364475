<template>
  <div>
    <b-sidebar
      id="sidebar-right-complete-profile"
      ref="sidebar_complete_profile"
      :visible="showOpenCompleteProfile"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-complete-profile', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #fff; height: 41px;"
        >
          <b-card
            no-body
            class="overflow-hidden"
            style="background-color: #fff;"
          >
            <b-row
              no-gutters
              class="mt-1 ml-2"
            >
            </b-row>
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div
          class="b-sidebar-body px-3"
          style="overflow-x: hidden;"
        >
          <account-setting-general :totalCols="'12'" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import AccountSettingGeneral from '@/views/pages/account-setting/AccountSettingGeneral.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    ClaimOwnership,
    AccountSettingGeneral,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenCompleteProfile',
    event: 'update:show-open-complete-profile',
  },
  props: ['idsite', 'showOpenCompleteProfile'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
    }
  },
  watch: {
    // idsite(val) {
      // console.log(val)
    //   if(val) {
    //     this.userData = {}
    //     const ref = db.collection('Users').doc(val)
    //     ref.onSnapshot(doc => {
    //       this.usuarios = {}
    //       this.usuarios = {
    //           id: doc.data().uid,
    //           fullName: doc.data().nombre,
    //           firstName: doc.data().firstName ? doc.data().firstName : '',
    //           lastName: doc.data().lastName ? doc.data().lastName : '',
    //           email: doc.data().email,
    //           role: doc.data().role,
    //           avatar: doc.data().foto,
    //           contact: doc.data().contact ? doc.data().contact : '',
    //           permissions: doc.data().permissions ?  doc.data().permissions : [],
    //       }
    //       this.emailOld = doc.data().email
    //       this.userData = this.usuarios
    //     })
    //   }
    // },
    showOpenCompleteProfile(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        localStorage.setItem('openComplete', false)
      }
    }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_complete_profile.hide()
    },
    activateFunction() {
      const user = auth.currentUser
      if (user) {
        // console.log(this.idsite.id)
        db.collection('sites').doc(this.idsite.id).update({
          status: 'active',
        })
        .then(() => {
        // console.log(this.userData.uid)
          db.collection('Users').doc(user.uid).update({
            updated: Date.now(),
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Site archived',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$refs.sidebar_complete_profile.hide()
        })
      }
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-complete-profile {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
